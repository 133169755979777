<template>
  <div  :class="['alert', 'alert-'+type]" role="alert">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'TheAlert',
  props: {
    type: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
  .alert{
    border: unset;
  }
</style>
