<template>
  <div class="form-group" :class="formGroupClass" >
    <label
        v-if="label"
        :style="{ 'margin-top': (modelValue?.toString().length>0) ? '-20px': '0'}">
      {{ label }}
    </label>
    <input
        ref="input"
        class="form-control"
        :class="[type, (error)?'is-invalid':'' ]"
        :type="type"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        @input="updateValue"
    >
  </div>
  <div v-if="error" class="valid-feedback">
    {{ error }}
  </div>
</template>

<script>

export default {
  name: 'TheInput',
  props: {
    modelValue: [String, Number],
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: false,
    },
    error: String,
    formGroupClass: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    min: Number,
    maskPattern: [String, Object],
  },
  setup(props, context) {
    const updateValue = (event) => {
      context.emit('update:modelValue', event.target.value);
    };
    return { updateValue };
  }

};
</script>

<style scoped>
.form-group {
  overflow: hidden;
  margin-bottom: 0;
  border: 1px solid #efefef;
  padding: 15px 15px;
  border-bottom: none;
  position: relative;
}
.form-group label {
  font-size: 12px;
  display: block;
  margin-bottom: 0;
  color: #b3b3b3;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;

}
.form-control {
  border: none;
  padding: 0;
  font-size: 20px;
  border-radius: 0;

}
.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-group.first {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.form-group.last {
  border-bottom: 1px solid #efefef;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  margin-bottom: 25px;
}
</style>
