<template>
  <button  :disabled="disabled">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'TheButtonSvg',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
    button{
      border: unset;
      background-color: unset;
      transition: 0.1s linear;
    }
    button:hover {
      transform: translate3d(0, -2px, 0);
      -webkit-transform: translate3d(0, -2px, 0);
      filter: invert(88%) sepia(16%) saturate(6692%) hue-rotate(343deg) brightness(101%) contrast(101%);
    }
    button.filter-white:hover{
      filter: unset;
    }

</style>
