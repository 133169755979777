import axios from "axios";
import config from "@/config";
import router from "@/router";

export const authModule = {
    state: () => ({
        credential: {
            token: localStorage.getItem('token') ?? null,
            refresh_token: localStorage.getItem('refresh_token') ?? null,
            username: null,
            password: null,
        },
        error: null
    }),
    getters: {
        isAuth: (state) => state.credential.token !== null,
        logout: (state) => {
            state.credential = {
                token:  null,
                refresh_token: null,
                username: null,
                password: null,
            }
            localStorage.removeItem('token')
            localStorage.removeItem('refresh_token')
            router.push({ name: 'users:login'})
        },
    },
    mutations: {
        setUsername(state, username) {
            state.credential.username = username;
        },
        setPassword(state, password) {
            state.credential.password = password;
        },
        setToken(state, data) {
            if (data) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('refresh_token', data.refresh_token);
                state.credential.token = data.token;
                state.credential.refresh_token = data.refresh_token;
            } else {
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                state.credential.token = null;
                state.credential.refresh_token = null;
            }
        },
    },
    actions: {
        login({state, commit}) {
            state.error = null;
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            axios.post(config.hostname + '/api/token', {
                username: state.credential.username,
                password: state.credential.password
            }, axiosConfig).then((response) => {
                commit('setToken', response.data);
                router.push({name: 'plots:index'})
            }) .catch((err) => {
                state.error = err.response?.data?.message;
                if(!state.error){
                    state.error = err.message;
                }
                commit('setToken', null);
             })
        }
    },
    namespaced: true
}
