import { createRouter, createWebHistory } from 'vue-router'
import LoginView from "@/views/Users/LoginView";
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import middlewarePipeline from "@/router/middlewarePipeline";
import store from "@/store";

const routes = [
  {
    path: '/',
    redirect: to => {
      return { path: '/plots' }
    },
  },
  {
    path: '/privacy',
    name: 'rules:privacy',
    component: () => import('@/views/rules/PrivacyPage.vue'),

  },
  {
    path: '/plots/:point_id?',
    name: 'plots:index',
    component: () => import('@/views/Plots/IndexView'),
    meta:{
      middleware:  [auth]
    }
  },
  {
    path: '/points',
    name: 'points:index',
    component: () => import('@/views/Points/IndexView'),
    meta:{
      middleware:  [auth]
    }
  },
  {
    path: '/posts',
    name: 'posts:index',
    component: () => import('@/views/Posts/IndexView'),
    meta:{
      middleware:  [auth]
    }
  },
  {
    path: '/references',
    name: 'references:index',
    component: () => import('@/views/References/IndexView'),
    meta:{
      middleware:  [auth]
    }
  },
  {
    path: '/resumes',
    name: 'resumes:index',
    component: () => import('@/views/Resumes/IndexView'),
    meta:{
      middleware:  [auth]
    }
  },
  {
    path: '/values',
    name: 'values:index',
    component: () => import('@/views/Values/IndexView'),
    meta:{
      middleware:  [auth]
    }
  },
  {
    path: '/vacancies',
    name: 'vacancies:index',
    component: () => import('@/views/Vacancies/IndexView'),
    meta:{
      middleware:  [auth]
    }
  },
  {
    path: '/login',
    name: 'users:login',
    component: LoginView,
    meta:{
      middleware:  [guest]
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  if (!to.meta?.middleware) {
    return next();
  }

  const { middleware } = to.meta;
  const context = {
    to, from, next, store,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});


export default router
