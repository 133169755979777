<template>

  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container">
      <a class="navbar-brand" href="/">
        <img width="50" src="@/assets/logo.png" alt="">

        ООО «Сибуголь»</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul  v-if="isAuth"  class="navbar-nav me-auto mb-2 mb-lg-0">

          <li class="nav-item">
            <router-link :to="{ name: 'points:index'}" class="nav-link">
              <span>Участки</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link :to="{ name: 'plots:index'}" class="nav-link">
              <span>Точки/Продукции</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'posts:index'}" class="nav-link">
              <span>Новости</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'references:index'}" class="nav-link">
              <span>Справочник</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'vacancies:index'}" class="nav-link">
              <span>Вакансии</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'resumes:index'}" class="nav-link">
              <span>Резюме</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'values:index'}" class="nav-link">
              <span>Переменные</span>
            </router-link>
          </li>
        </ul>
        <TheButtonSvg v-if="isAuth"  @click="logout"  class="filter-white d-flex">
          <svg style="filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(259deg) brightness(109%) contrast(101%);" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"/>
            <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
          </svg>
        </TheButtonSvg>
      </div>
    </div>
  </nav>

<!--  <nav class="navbar navbar-dark bg-dark">-->
<!--    <div class="container">-->
<!--      <a class="navbar-brand" href="/">-->
<!--        ООО «Сибуголь»-->
<!--      </a>-->


<!--      <TheButtonSvg v-if="isAuth"  @click="logout" class="filter-white">-->
<!--        <svg style="filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(259deg) brightness(109%) contrast(101%);" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-box-arrow-left" viewBox="0 0 16 16">-->
<!--          <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"/>-->
<!--          <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>-->
<!--        </svg>-->
<!--      </TheButtonSvg>-->

<!--    </div>-->
<!--  </nav>-->
</template>

<script>
import TheButtonSvg from "@/components/ui/TheButtonSvg";
import store from "@/store";
export default {
  components: {TheButtonSvg},

  data() {
    return {
      toggle: true,
      isAuth: store.getters['auth/isAuth']
    }
  },
  methods: {
    logout(){
      store.getters['auth/logout'];
    }
  },


}
</script>

<style scoped>

</style>
