<template>
  <button class="btn"  :disabled="disabled">
    <slot />
  </button>
</template>


<script>

export default {
  name: 'TheButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.btn {
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  padding: 9px 26px;
  border: 1px solid #252525;
  font-size: inherit;
  line-height: 1.85714286em;
  width: 100%;
}
.btn:hover {
  transform: translate3d(0, -2px, 0);
  -webkit-transform: translate3d(0, -2px, 0);
  background-color: #e7ab16 !important;
  color: #fff!important;
}
.btn--primary, .btn--primary:visited {
  background: #fbba18;
  border-color: #fbba18;
  color: #fff;
}
</style>
