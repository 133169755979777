<template>
  <TheHeader></TheHeader>
  <div class="container">
    <div class="row align-items-center justify-content-center mt-5 pt-5">
      <div class="col-md-5">
        <h3>Войти</h3>
        <form method="post" @submit="auth" @submit.prevent>
          <TheAlert type="danger" v-if="error">{{ error }}</TheAlert>
          <TheInput type="email" label="Email" :model-value="credential.username" @update:model-value="setUsername"
                    formGroupClass="first"></TheInput>
          <TheInput type="password" label="Пароль" :model-value="credential.password" @update:model-value="setPassword"
                    formGroupClass="last"></TheInput>
          <TheButton class="btn--primary" type="submit">Войти</TheButton>
        </form>
      </div>

    </div>
  </div>

</template>

<script>
import TheHeader from "@/components/ui/TheHeader";
import TheInput from "@/components/ui/TheInput";
import TheButton from "@/components/ui/TheButton";
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
import TheAlert from "@/components/ui/TheAlert";

export default {
  name: 'LoginView',
  metaInfo: {
    title: 'Login',
    titleTemplate: '%s | My Awesome Webapp'
  },
  components: {TheAlert, TheButton, TheInput, TheHeader},
  data() {
    return {}
  },
  computed: {
    ...mapState({
      credential: state => state.auth.credential,
      error: state => state.auth.error,
    }),
  },
  methods: {
    ...mapMutations({
      'setUsername': 'auth/setUsername',
      'setPassword': 'auth/setPassword'
    }),
    ...mapActions({
      'login': 'auth/login'
    }),
    auth(){
      this.login();
    }
  }
}
</script>

<style scoped>

</style>
